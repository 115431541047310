import CryptoJS from 'crypto-js';
import env from "../http/env";

// 格式化日期 2022-05-06
export const formatDate = function(value) {
	var date = new Date(value);
	var year = date.getFullYear();
	var month = (date.getMonth() + 1) < 10 ? "0" + (date.getMonth() + 1) : (date.getMonth() + 1);
	var day = date.getDate() < 10 ? "0" + date.getDate() : date.getDate();
	return year + '-' + month + '-' + day;
}


// 处理富文本内容只获取纯文字并截取100个
export const filtersText = function(val) {
	if (val != null && val != '') {
		val = val.replace(/<(?:.|\n)*?>/gm, '')
			.replace(/(&rdquo;)/g, '\"')
			.replace(/&ldquo;/g, '\"')
			.replace(/&mdash;/g, '-')
			.replace(/&nbsp;/g, '')
			.replace(/&gt;/g, '>')
			.replace(/&lt;/g, '<')
			.replace(/<[\w\s"':=\/]*/, '');
		return val.substr(0, 100);
	} else return '';
}

export function encryptData(word) {
	var app_key = env.dev.app_key // env配置16位字符串可以写死一样的
	var app_iv = env.dev.app_iv // env配置16位字符串可以写死一样的
	var key = CryptoJS.enc.Utf8.parse(app_key)
	var iv = CryptoJS.enc.Utf8.parse(app_iv)
	var srcs = CryptoJS.enc.Utf8.parse(word)
	var encrypted = CryptoJS.AES.encrypt(srcs, key, {
		iv: iv,
		mode: CryptoJS.mode.CBC,
		padding: CryptoJS.pad.Pkcs7
	})
	return encrypted.toString()
	// return cryptoJs.enc.Base64.stringify(cryptoJs.enc.Utf8.parse(encrypted)).toString()
}

export function decryptData(data) {
	var app_key = env.dev.app_key // env配置16位字符串可以写死一样的
	var app_iv = env.dev.app_iv // env配置16位字符串可以写死一样的
	var key = CryptoJS.enc.Utf8.parse(app_key)
	var iv = CryptoJS.enc.Utf8.parse(app_iv)
	var decrypt = CryptoJS.AES.decrypt(data, key, {
		iv: iv,
		mode: CryptoJS.mode.CBC,
		padding: CryptoJS.pad.Pkcs7
	})
	return decrypt.toString(CryptoJS.enc.Utf8)
	// return cryptoJs.enc.Utf8.stringify(decrypt).toString()
}