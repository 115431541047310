<template>
  <div class="job-talent">
    <p v-show="jtIndex==0" style="position: absolute;right: 0;top:10px;font-size: 14px;z-index: 1" id="pmore1">
      <a href="javascript:;" @click="url_more('/job')" style="color: #000000;text-decoration: none"> >> 更多 </a>
    </p>
    <p v-show="jtIndex==1" style="position: absolute;right: 0;top:10px;font-size: 14px;z-index: 1" id="pmore2">
      <a href="javascript:;" @click="url_more('/talent')" style="color: #000000;text-decoration: none"> >> 更多 </a>
    </p>
    <el-tabs v-model="activeName" @tab-click="handleClick">
      <el-tab-pane label="最新岗位" name="job">
        <div class="job-info">
          <div class="job-list" v-for="(item,index) in jobtalentdata" :key="index"
               @click="btntest(item.EnJobId)">
            <div class="top">
              <div class="title">
                <span class="jobname">{{ item.JobName }}</span>
                <span class="tag"
                      v-show="item.recruit_status==1">{{ item.recruit_status == 1 ? '急聘' : item.zhuangtai }}</span>
                <span class="tag2" v-show="item.zhuangtai!='无'&&item.recruit_status!=1">{{ item.zhuangtai }}</span>
              </div>
              <div class="salary">{{ item.SalaryBk }}</div>
            </div>
            <div class="center">
              <div class="company">{{ item.CompanyName }}</div>
            </div>
            <div class="center1 mt-16">
              <span>学历要求：{{ item.Education == '其他' ? '暂无要求' : item.Education }}</span>
              <span>工作经验：{{ item.WorkExperience == '其他' ? '暂无要求' : item.WorkExperience }}</span>
            </div>
            <div class="address"><i class="iconfont icon-dizhi"></i>{{ item.Address }}</div>
            <div class="yp">岗位详情</div>
          </div>
        </div>
      </el-tab-pane>
      <el-tab-pane label="最新人才" name="talent">
        <div class="talent-info">
          <div class="job-list" v-for="(item,index) in rencaidata" :key="index" @click="tiao(item.Id)">
            <div class="top">
              <div class="title">{{ item.TrueName }}</div>
              <div class="salary">{{ item.Salary == '其他' ? '面议' : item.Salary }}</div>
            </div>
            <div class="center">
              <div class="company">
                <div class="spanzq" v-show="item.JobPosition1Name1">
                  {{ item.JobPosition1Name1 }}
                </div>
                <span v-show="item.JobPosition1Name2">|</span>
                <div class="spanzq" v-show="item.JobPosition1Name2">
                  {{ item.JobPosition1Name2 }}
                </div>
                <span v-show="item.JobPosition1Name3">|</span>
                <div class="spanzq" v-show="item.JobPosition1Name3">
                  {{ item.JobPosition1Name3 }}
                </div>
              </div>
              <!-- <div class="demand">{{item.Education}} | {{item.WorkExperience}}</div> -->
            </div>
            <div class="center1 mt-16">
              <span>学历：{{ item.Education == '其他' ? '待完善' : item.Education }}</span>
              <span>工作经验：{{ item.WorkExperience == '其他' ? '待完善' : item.WorkExperience }}</span>
            </div>
            <div class="yp">人才详情</div>
          </div>
        </div>
      </el-tab-pane>
    </el-tabs>
    <!-- <div class="summary">求职快人一步，立即<span @click="goto()">免费登记简历</span></div> -->
  </div>

</template>

<script>
// import {getUserrecommend} from '../../http/api'
export default {
  name: 'JobTalent',
  props: ['jobtalentdata', 'rencaidata'],
  data() {
    return {
      activeName: 'job',
      jtIndex: 0,
    };
  },
  watch: {
    jobtalentdata: {
      handler(newV, oldV) {
        // console.log(newV, oldV)
      }
    },
    rencaidata: {
      handler(newV, oldV) {
        // console.log(newV, 'll')
      }
    }
  },
  created() {
    // this.getdata();
  },
  methods: {
    goto() {
      this.$router.push('/people')
    },
    handleClick(tab, event) {
      this.jtIndex = tab.index;
    },
    url_more(url) {
      this.$router.push(url);
    },
    btntest(id) {
      // console.log(id)
      this.$router.push({
        path: '/Jobdetails',
        query: {
          id
        }
      }) // 跳转到B
    },
    tiao(id) {
      // console.log(id)
      this.$router.push({
        path: '/TalentDetails',
        query: {
          jobs_id: id
        }
      })
    }
    // getdata(){
    // 	getUserrecommend(805049).then((res) => {
    // 		console.log(res,99999);
    // 		this.jobData=res.data.data

    // 	}).catch((err) => {
    // 		console.log(err);
    // 	});

    // },
  }

}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
.job-talent {
  width: 1200px;
  margin-top: 30px;
  // background: #FFFFFF;
  position: relative;

  .summary {
    height: 13px;
    font-size: 14px;
    font-family: Microsoft YaHei;
    font-weight: 400;
    color: #999999;
    line-height: 24px;
    position: absolute;
    top: 6px;
    right: 15px;
    cursor: pointer;

    span {
      color: #4670FF;
    }
  }

  .job-info {
    background: #FFFFFF;
    display: flex;
    flex-wrap: wrap;

    .job-list {
      width: 400px;
      // height: 170px;
      height: 180px;
      border: 1px solid #F4F4F4;
      padding: 20px 15px;
      font-size: 13px;
      font-family: Microsoft YaHei;
      font-weight: 400;
      color: #666666;
      line-height: 24px;
      position: relative;
      cursor: pointer;

      .top {
        display: flex;
        justify-content: space-between;
        margin-bottom: 10px;

        .title {
          font-size: 20px;
          font-family: Microsoft YaHei;
          font-weight: 600;
          color: #000000;
          line-height: 24px;
          width: 75%;

          display: flex;

          span.jobname {
            max-width: 70%;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
          }

          span.tag {
            margin-left: 10px;
            font-size: 12px;
            font-family: Microsoft YaHei;
            font-weight: 400;
            color: #FFFFFF;
            line-height: 24px;
            background: #FF5657;
            padding: 0px 10px;
            border-radius: 9px 8px 8px 0px;
          }

          span.tag2 {
            margin-left: 10px;
            font-size: 12px;
            font-family: Microsoft YaHei;
            font-weight: 400;
            color: #FFFFFF;
            line-height: 24px;
            background: #4670FF;
            padding: 0px 10px;
            border-radius: 9px 8px 8px 0px;
          }
        }

        .salary {
          font-size: 15px;
          font-family: Microsoft YaHei;
          font-weight: 400;
          color: #FF6700;
          line-height: 24px;
        }
      }

      .center {
        display: flex;
        // justify-content: space-between;

        .company {
          font-size: 15px;
          font-family: Microsoft YaHei;
          font-weight: 400;
          color: #666666;
          line-height: 24px;
          text-overflow: -o-ellipsis-lastline;
          overflow: hidden;
          text-overflow: ellipsis;
          display: -webkit-box;
          -webkit-line-clamp: 2;
          line-clamp: 2;
          -webkit-box-orient: vertical;
          // max-width: 75%;
        }
      }

      .center1 {
        // display: flex;
        // justify-content: space-between;
        font-size: 12px;
        text-align: initial;
      }

      .center1 span:first-child {
        margin-right: 20px;
      }

      .mt-16 {
        margin-top: 16px;
      }

      .address {
        width: 60%;
        height: 30px;
        position: absolute;
        left: 12px;
        bottom: 20px;
        font-size: 8px;
        text-align: left;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        width: 30em;
      }

      .yp {
        width: 101px;
        height: 29px;
        border: 1px solid #4670FF;
        border-radius: 14px;
        font-size: 13px;
        font-family: Microsoft YaHei;
        font-weight: 400;
        color: #4670FF;
        line-height: 26px;
        position: absolute;
        right: 15px;
        bottom: 20px;
        border: 1px solid #4670FF;
        border-radius: 14px;
        cursor: pointer;
      }
    }
  }

  .talent-info {
    background: #FFFFFF;
    display: flex;
    flex-wrap: wrap;

    .job-list {
      width: 400px;
      // height: 170px;
      height: 180px;
      border: 1px solid #F4F4F4;
      padding: 20px 15px;
      font-size: 13px;
      font-family: Microsoft YaHei;
      font-weight: 400;
      color: #666666;
      line-height: 24px;
      position: relative;
      cursor: pointer;

      .top {
        display: flex;
        justify-content: space-between;
        margin-bottom: 10px;

        .title {
          // font-size: 13px;
          font-size: 20px;
          font-family: Microsoft YaHei;
          // font-weight: 400;
          font-weight: 600;
          color: #000000;
          line-height: 24px;
          max-width: 75%;
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;

          span.tag {
            margin-left: 10px;
            font-size: 12px;
            font-family: Microsoft YaHei;
            font-weight: 400;
            color: #FFFFFF;
            line-height: 24px;
            background: #FF5657;
            padding: 0px 10px;
            border-radius: 9px 8px 8px 0px;
          }
        }

        .salary {
          font-size: 15px;
          font-family: Microsoft YaHei;
          font-weight: 400;
          color: #FF6700;
          line-height: 24px;
        }
      }

      .center {
        display: flex;
        justify-content: space-between;

        .company {
          // font-size: 13px;
          // font-family: Microsoft YaHei;
          // font-weight: 400;
          // color: #666666;
          // line-height: 24px;
          // text-overflow: -o-ellipsis-lastline;
          // overflow: hidden;
          // text-overflow: ellipsis;
          // display: -webkit-box;
          // -webkit-line-clamp: 2;
          // line-clamp: 2;
          // -webkit-box-orient: vertical;
          // // max-width: 75%;
          height: 24px;
          width: 100%;
          display: flex;
          overflow: hidden;

          .spanzq {
            // // background: #03a9f4;
            // border-radius: 14px;
            // // padding: 0 10px;
            // font-size: 12px;
            // // color: #ffffff;
            // width: 30%;
            // height: 30px;
            // line-height: 30px;
            // overflow: hidden;
            // text-overflow: ellipsis;
            // white-space: nowrap;
            // margin-left: 5%;
            max-width: 33%;
            text-align: initial;
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
          }

          .spanzq:first-child {
            // margin-left: 0;
          }

          span {
            margin: 0 10px;
          }
        }
      }

      .center1 {
        // display: flex;
        // justify-content: space-between;
        font-size: 12px;
        text-align: initial;
      }

      .center1 span:first-child {
        margin-right: 20px;
      }

      .mt-16 {
        margin-top: 16px;
      }

      .address {
        position: absolute;
        left: 12px;
        bottom: 20px;
        font-size: 8px;
        text-align: left;

      }

      .yp {
        width: 101px;
        height: 29px;
        border: 1px solid #4670FF;
        border-radius: 14px;
        font-size: 13px;
        font-family: Microsoft YaHei;
        font-weight: 400;
        color: #4670FF;
        line-height: 26px;
        position: absolute;
        right: 15px;
        bottom: 20px;
        border: 1px solid #4670FF;
        border-radius: 14px;
        cursor: pointer;
      }
    }
  }

  :deep .el-tabs__header {
    margin: 0px;
  }

  :deep .el-tabs__item.is-active {
    color: #000000;
    font-size: 24px;
    font-family: Microsoft YaHei;
    font-weight: 400;
    color: #000000;
    line-height: 24px;
  }

  :deep .el-tabs__item {
    font-size: 18px;
    font-family: Microsoft YaHei;
    font-weight: 400;
    color: #666666;
    line-height: 24px;
    padding: 0 30px;
  }

  :deep .el-tabs__nav-wrap::after {
    background-color: #4670FF;
  }

  :deep .el-tabs__active-bar {
    height: 0;
  }

  :deep .el-tabs__item:hover {
    color: #000000;
  }

  :deep .el-tabs__active-bar {
    background-color: #000000;
  }
}
</style>
