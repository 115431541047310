<template>
	<div class="links-list">
		<div class="list-item" >
			<a href="http://www.wenjiang.gov.cn/" target="_blank">温江公众信息网</a>
			<a href="http://www.wjzhdj.com/WebSiteNew/contents/97/29930.html" target="_blank">温江共青团</a>
			<a href="https://www.rc114.com/index.aspx" target="_blank">成都人才网</a>
			<a href="http://cdhrss.chengdu.gov.cn/" target="_blank">成都人力资源和社会保障局</a>
			<a href="http://www.svtcc.edu.cn/" target="_blank">四川交通职业技术学院</a>
		</div>
		
	</div>

</template>

<script>
	export default {
		name: 'Links',
		data() {
			return {
			

			};
		},
		methods: {

		}

	}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
	.links-list {
		margin-top: 20px;
		background: #FFFFFF;
		.list-item{
			text-align: left;
			padding: 20px;
			a{
				text-decoration: none;
				display: inline-block;
				font-size: 14px;
				font-family: Microsoft YaHei;
				font-weight: 400;
				color: #666666;
				line-height: 24px;
				margin: 5px 0px;
				margin-right: 54px;
				
			}
		}
	}
</style>
