export default {
	// 开发环境
	dev: {
		baseUrl: "https://api.jywj12333.com",
		app_key: "3E503F0AEB598D3F",
		app_iv: "09CAF8617776E2CA",
		// baseUrl: "http://127.0.0.1",
	},
	// //线上接口
	// prod: {
	//     baseUrl: "http://127.0.0.1:8000/api"
	// }
};