<template>
	<div class="news-content">
		<el-tabs v-model="activeName" @tab-click="handleClick">
			<el-tab-pane :label="key" :name="key" v-for="(val,key,i) in newsdata" :key="i">
				<div class="news-list">
					<div class="news-item" v-for="(item,index) in val" :key="index" @click="goto(item.Id)">
						<div class="title">{{item.Title}}</div>
						<div class="date">{{item.CreateTime|formatDate}}</div>
					</div>
				</div>
			</el-tab-pane>
			<!-- <el-tab-pane label="人才政策" name="second">
				<div class="news-list">
					<div class="news-item" v-for="(item,index) in rczcData" :key="index">
						<div class="title">{{item.title}}</div>
						<div class="date">{{item.date}}</div>
					</div>
				</div>
			</el-tab-pane>
			<el-tab-pane label="就业指导" name="third">
				<div class="news-list">
					<div class="news-item" v-for="(item,index) in jyzdData" :key="index">
						<div class="title">{{item.title}}</div>
						<div class="date">{{item.date}}</div>
					</div>
				</div>
			</el-tab-pane>
			<el-tab-pane label="创业指导" name="fourth">
				<div class="news-list">
					<div class="news-item" v-for="(item,index) in cyzdData" :key="index">
						<div class="title">{{item.title}}</div>
						<div class="date">{{item.date}}</div>
					</div>
				</div>
			</el-tab-pane> -->
		</el-tabs>
		<!-- <div class="more">更多></div> -->
	</div>

</template>

<script>
	export default {
		name: 'News',
		props:['newsdata'],
		data() {
			return {
				activeName: '人才中心',
			};
		},
		watch:{
			newsdata:{
				handler(newV,oldV){
					// console.log(newV,oldV)
				}
			}
		},
		methods: {
			goto(id){
				this.$router.push({path:'/talentxiangqing',query:{usid:id}});
			},
			handleClick(tab, event) {
				// console.log(tab, event);
			}
		}

	}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
	.news-content {
		width: 596px;
		height: 360px;
		background: #FFFFFF;
		position: relative;
		.more{
			height: 11px;
			font-size: 10px;
			font-family: Microsoft YaHei;
			font-weight: 400;
			color: #999999;
			line-height: 24px;
			position: absolute;
			top: 5px;
			right: 15px;
			cursor: pointer;
		}
		.news-list{
			padding: 0px 20px;
			.news-item{
				font-size: 13px;
				font-family: Microsoft YaHei;
				font-weight: 400;
				color: #666666;
				line-height: 24px;
				display: flex;
				justify-content: space-between;
				margin: 20px 0px;
				cursor: pointer;
				.title{
					    max-width: 80%;
					    white-space: nowrap;
					    overflow: hidden;
					    text-overflow: ellipsis;
				}
				.date{
					color: #999999;
				}
			}
		}
		:deep .el-tabs__header {
			padding: 0px 15px;
		}
		
		:deep .el-tabs__item.is-active {
			color: #000000;
			font-size: 16px;
		}
		
		:deep .el-tabs__item {
			color: #666666;
		}
		
		:deep .el-tabs__item:hover {
			color: #000000;
		}
		
		:deep .el-tabs__active-bar {
			background-color: #000000;
		}
	}
</style>
