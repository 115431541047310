<template>
	<div class="fuc-list">

		<el-row :gutter="10">
			<el-col :span="6">
				<div class="list-item color1" @click='mapbtn()'>
					人力资源地图
				</div>
			</el-col>
			<el-col :span="6">
				<!-- <div class="list-item color2" @click="testbtn()">
                    校园招聘
                </div> -->
				<div class="list-item color2" @click="weibtn()">
					校园招聘
				</div>
			</el-col>
			<el-col :span="6">
				<div class="list-item color3" @click="yuanqubtn()">
					园区招聘
				</div>
				<!-- <div class="list-item color3" @click="weibtn()">
                    园区招聘
                </div> -->
			</el-col>
			<el-col :span="6">
				<div class="list-item color4" @click="weibtn(3)">
					网络学院
				</div>
			</el-col>
		</el-row>
		<el-row :gutter="10">
			<el-col :span="6">
				<div class="list-item color5" @click="zhucanbtn(1)">
					助残服务
				</div>
				<!-- <div class="list-item color5" @click="weibtn()">
                    助残服务
                </div> -->
			</el-col>
			<el-col :span="6">
				<div class="list-item color6" @click="zhucanbtn(2)">
					退役军人
				</div>
				<!-- <div class="list-item color6" @click="weibtn()">
                    退役军人
                </div> -->
			</el-col>
			<el-col :span="6">
				<div class="list-item color7" @click="zhucanbtn(3)">
					政府招聘
				</div>
				<!-- <div class="list-item color7"
                     @click="weibtn()">
                    政府招聘
                </div> -->
			</el-col>
			<el-col :span="6">
				<div class="list-item color8" @click="weibtn()">
					就业实习/见习
				</div>
			</el-col>
		</el-row>
	</div>

</template>

<script>
	// import { getBanner,getJoblists,getUserrecommend } from "../../http/api";
	export default {
		name: 'FucList',
		data() {
			return {


			};
		},
		methods: {
			weibtn() {
				alert('暂未开放')
			},
			zhengfubtn() {
				this.$router.push('/zhengqi')
			},
			mapbtn() {
				this.$router.push('/ziyuanmap')
			},
			yuanqubtn() {
				this.$router.push('/yuanqu')
			},
			zhucanbtn(id) {
				this.$router.push({
					path: '/jobanorther',
					query: {
						id: id
					}
				})
			},
			testbtn() {
				getBanner().then((res) => {
					console.log(res);
				}).catch((err) => {
					console.log(err);
				});


				getJoblists().then((res) => {
					console.log(res, 1111);
				}).catch((err) => {
					console.log(err);
				});

				getUserrecommend(2).then((res) => {
					console.log(res, 222);
				}).catch((err) => {
					console.log(err);
				});
			},
		}

	}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
	.fuc-list {
		background: #ffffff;
		margin-top: 20px;

		.el-row {
			margin-bottom: 10px;
		}

		.list-item {
			width: 293px;
			height: 90px;

			border-radius: 2px;
			font-size: 18px;
			font-family: Microsoft YaHei;
			font-weight: 400;
			color: #ffffff;
			display: table-cell;
			vertical-align: middle;
			cursor: pointer;
		}

		.color1 {
			background: linear-gradient(158deg, #5384ea 0%, #6cadf6 100%);
		}

		.color2 {
			background: linear-gradient(158deg, #5a4fe9 0%, #7970ea 100%);
		}

		.color3 {
			background: linear-gradient(158deg, #e84f3d 0%, #f07b4a 100%);
		}

		.color4 {
			background: linear-gradient(158deg, #e83c50 0%, #f76776 100%);
		}

		.color5 {
			background: linear-gradient(158deg, #626c9b 0%, #8790b2 100%);
		}

		.color6 {
			background: linear-gradient(158deg, #18ab6b 0%, #2fc687 100%);
		}

		.color7 {
			background: linear-gradient(158deg, #4a75e6 0%, #7299f0 100%);
		}

		.color8 {
			background: linear-gradient(158deg, #594fe8 0%, #867eed 100%);
		}
	}
</style>