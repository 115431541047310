<template>
	<div class="search-content section">
		<div class="logo">
			<img src="../../assets/home/logo.png" alt="">
		</div>
		<div class="search">
			<el-input placeholder="搜索职位、公司关键词" v-model="input2">
				<el-button slot="append" icon="el-icon-search" @click="tiaobtn()">搜素</el-button>
			</el-input>
		</div>

		<div class="topimg" v-show="showflag" @mouseleave="showfalse()">
			<div class="left-box">
				<div class="l">
					<span>官方公众号</span>
				</div>
				<div class="r"></div>
			</div>
			<div class="right-box">
				<div class="l">
					<span>官方小程序</span>
				</div>
				<div class="r"></div>
			</div>

		</div>
		<div class="list" @mouseover="showgongzong()">
			<div class="icon" v-show="!gongzhong"><img src="../../assets/home/gzh.png" alt=""></div>
			<div class="text" v-show="!gongzhong">
				<div class="txt black">关注公众号</div>
				<div class="txt">掌握最新求职动态</div>
			</div>
			<div class="gongz" v-show="gongzhong" @mouseleave='hidegongzong()'>
				<div class="left">
					<span>官方公众号</span>
				</div>
				<div class="right"></div>
			</div>
		</div>
		<div class="list" @mouseover="showxcx()">
			<div class="icon" v-show="!xcx">
				<img src="../../assets/home/xcx.png" alt="">
			</div>
			<div class="text" v-show="!xcx">
				<div class="txt black">添加小程序</div>
				<div class="txt">随时随地找工作</div>
			</div>

			<div class="xiaochengx" v-show="xcx" @mouseleave='hidexcx()'>
				<div class="left">
					<span>官方小程序</span>
				</div>
				<div class="right"></div>
			</div>
		</div>

	</div>
</template>

<script>
	export default {
		name: 'SearchRegion',
		data() {
			return {
				input2: '',
				showflag: false,
				gongzhong: false,
				xcx: false
			}
		},
		methods: {
			hidexcx() {
				this.xcx = false
			},
			showxcx() {
				this.xcx = true
			},
			hidegongzong() {
				this.gongzhong = false
			},
			showgongzong() {
				this.gongzhong = true
			},
			showlist() {
				this.showflag = true
			},
			showfalse() {
				this.showflag = false
			},
			tiaobtn() {
				if (this.input2 != '') {
					this.$router.push({
						path: '/job',
						query: {
							nametex: this.input2
						}
					})
				}
			},
		},

	}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
	.search-content {
		display: flex;
		justify-content: space-between;
		height: 140px;
		align-items: center;

		.search {
			width: 542px;
		}

		.topimg {
			width: 350px;
			height: 100px;
			background: #f00;
			position: absolute;
			right: 28%;
			display: flex;
			align-items: center;

			.left-box {
				width: 50%;
				height: 100%;
				background: #4670FF;
				display: flex;

				.l {
					width: 40%;
					background: #fff;
					display: flex;
					align-items: center;
					justify-content: center;

					span {
						width: 100%;
						height: 30px;
						font-size: 12px;

					}
				}

				.r {
					flex: 1;
					background: #333333;
					background: url('../../assets/gzh.jpg');
					background-size: 100% 100%;
				}
			}

			.right-box {
				width: 50%;
				height: 100%;
				background: #4670FF;
				display: flex;

				.l {
					width: 40%;
					background: #fff;
					display: flex;
					align-items: center;
					justify-content: center;

					span {
						width: 100%;
						height: 30px;
						font-size: 12px;

					}
				}

				.r {
					flex: 1;
					background: #333333;
					background: url('../../assets/xcx.jpg');
					background-size: 100% 100%;
				}
			}
		}
	}

	.el-input {
		width: 542px;
		height: 50px;
		background: #FFFFFF;
		border-radius: 10px;
		border-right: none;

		:deep input {
			height: 50px;
			font-size: 1rem;
			border: 2px solid #4670FF;
			border-radius: 10px;
			border-top-right-radius: 0px;
			border-bottom-right-radius: 0px;
			outline: none;
		}

		.el-button {
			background: #4670FF !important;
			width: 100px;
			height: 50px;
			border-radius: 0px 10px 10px 0px;
			color: #FFFFFF;
			font-size: 1rem;
		}
	}

	.list {
		width: 200px;
		height: 100px;
		display: flex;
		align-items: center;

		.gongz {
			width: 100%;
			height: 100%;
			display: flex;
			align-items: center;
			justify-content: center;

			.left {
				width: 50%;
				height: 100%;
				display: flex;
				align-items: center;
				justify-content: center;

				span {
					width: 100%;
					height: 20px;
					font-size: 14px;

				}
			}

			.right {
				width: 50%;
				height: 100%;
				background: url('../../assets/gzh.jpg');
				background-size: 100% 100%;
			}

			// background: url('../../assets/gzh.jpg');
			// background-size:100% 100% ;
		}

		.xiaochengx {
			width: 100%;
			height: 100%;
			display: flex;
			align-items: center;
			justify-content: center;

			.left {
				width: 50%;
				height: 100%;
				display: flex;
				align-items: center;
				justify-content: center;

				span {
					width: 100%;
					height: 20px;
					font-size: 14px;

				}
			}

			.right {
				width: 50%;
				height: 100%;
				background: url('../../assets/xcx.jpg');
				background-size: 100% 100%;
			}
		}

		.icon {
			width: 40px;
			height: 40px;
			background: #FFFFFF;
			border-radius: 50%;

			img {
				width: 100%;
				border-radius: 50%;
			}
		}

		.text {
			display: flex;
			flex-direction: column;
			justify-content: center;
			text-align: left;
			padding-left: 10px;

			.txt {
				font-size: 12px;
				font-family: PingFang SC;
				font-weight: 500;
				color: #999FA6;
				line-height: 24px;
			}

			.txt.black {
				font-size: 12px;
				font-family: PingFang SC;
				font-weight: bold;
				color: #333333;
				line-height: 24px;
			}
		}
	}
</style>