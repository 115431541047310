<template>
	<div class="job-menu">
		<div class="job-menu-box">
			<ul class="job-menu-box-ul1">
				<li class="job-menu-box-li1" v-for="(item) in toplist" :key="item.Id" @click="btn(item.Id)">
					<div>
						<span>{{item.Name}}</span>
						<span class="yj"></span>
					</div>
				</li>
				<!-- <li class="job-menu-box-li1" @click="quanbu()">
					<div>
						<span>全部职位</span>
					</div>
				</li> -->
			</ul>
		</div>
	</div>

</template>

<script>
	export default {
		name: 'JobMenu',
		props: ['toplist'],
		data() {
			return {};
		},
		watch: {
			toplist: {
				handler(newV, oldV) {
					// console.log(newV,oldV)
				}
			}
		},

		methods: {
			btn(id) {
				if (id == 0) {
					this.$router.push('/job');
				} else {
					this.$router.push({
						path: '/job',
						query: {
							positionid: id
						}
					})
				}

			},
			quanbu() {
				this.$router.push('/job');
			},
		}

	}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
	.job-menu {
		width: 100%;
		height: 100%;
		display: flex;
		align-items: center;

		.job-menu-box {
			width: 100%;
			height: 378px;

			ul li:hover {
				background: rgba(0, 0, 0, 1);
			}

			.job-menu-box-ul1 {
				width: 100%;
				height: 100%;

				.job-menu-box-li1 {
					width: 100%;
					height: 42px;
					// background: rgba(95, 95, 95, 1);
					display: flex;
					align-items: center;
					justify-content: center;
					cursor: pointer;

					div {
						width: 80%;
						height: 24px;
						font-size: 14px;
						font-weight: bold;
						color: #ffffff;
						display: flex;
						justify-content: space-between;

						.yj {
							width: 16px;
							height: 14px;
							background: url('~@/assets/home/yj.png');
							background-size: 100% 100%;
						}
					}
				}
			}
		}
	}
</style>
