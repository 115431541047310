<template>
	<div class="public-list">
		<el-row :gutter="10">
			<el-col :span="6">
				<div class="public-service">
					<div class="service-header">
						<div class="title">公共服务</div>
						<div class="more" @click="servicesList(firstservicestypeid)">更多></div>
					</div>
					<div class="service-content">
						<el-row :gutter="30">
							<el-col :span="8" v-for="(item,index) in servicesType" :key="index">
								<div class="service-item" @click="servicesList(item.Id)">
									<div class="item-icon">
										<img :src="item.Picture" alt="">
									</div>
									<div class="service-txt">
										{{item.Title}}
									</div>
								</div>
							</el-col>
							<el-col :span="8" v-show="dshow">
								<div class="service-item" @click="servicesList(firstservicestypeid)">
									<div class="item-icon">
										<img src="../../assets/home/qbfw.png" alt="">
									</div>
									<div class="service-txt">
										全部服务
									</div>
								</div>
							</el-col>
						</el-row>
					</div>
				</div>
			</el-col>
			<el-col :span="12">
				<News :newsdata='newslistdata'></News>
			</el-col>
			<el-col :span="6">
				<Zph :zphdata='zphlistdata'></Zph>
			</el-col>

		</el-row>
	</div>

</template>

<script>
	import {
		indexList,
		getServicesType,
		indexNews,
		indexRecruitment,
	} from "../../http/api";
	import {
		decryptData
	} from "../../utils/common.js";
	import News from '@/components/home/News.vue'
	import Zph from '@/components/home/zph.vue'
	import env from '../../http/env'
	export default {
		name: 'PublicService',
		components: {
			News,
			Zph
		},
		data() {
			return {
				newslistdata: '',
				zphlistdata: [],
				servicesType: [],
				dshow: false,
				firstservicestypeid: 0,
			};
		},
		created() {
			this.getservicestype();
			this.getNews()
			this.getRecruitment();
		},
		mounted() {

		},
		methods: {
			//获取列表数据
			getNews(data) {
				indexNews(data).then((res) => {
					this.newslistdata = JSON.parse(decryptData(res.data.data));
				}).catch((err) => {
					console.log(err);
				});
			},
			//获取列表数据
			getRecruitment(data) {
				indexRecruitment(data).then((res) => {
					let list = JSON.parse(decryptData(res.data.data));
					for (let i = list.length - 1; i >= 0; i--) {
						this.zphlistdata.push(list[i]);
					}
				}).catch((err) => {
					console.log(err);
				});
			},
			// 获取公共服务分类
			getservicestype() {
				getServicesType().then((res) => {
					let servicesType = JSON.parse(decryptData(res.data.data));
					let length = servicesType.length <= 8 ? servicesType.length : 8;
					for (let i = 0; i < length; i++) {
						this.servicesType.push(servicesType[i]);
						this.servicesType[i].Picture = env.dev.baseUrl + '/upload/' + this.servicesType[i].Picture;
					}
					this.firstservicestypeid = this.servicesType[0].id;
					this.dshow = true;
				})
			},
			// 跳转服务列表
			servicesList(id) {
				this.$router.push({
					path: '/services',
					query: {
						id
					}
				})
			},
			// 跳转资源下载
			servicesFile() {
				this.$router.push({
					path: '/servicesfile'
				})
			}
		}

	}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
	.public-list {
		height: 360px;
		margin-top: 30px;

		.public-service {
			width: 293px;
			height: 360px;
			background: #FFFFFF;

			.service-header {
				display: flex;
				justify-content: space-between;
				font-size: 16px;
				font-family: Microsoft YaHei;
				font-weight: 400;
				color: #000000;
				padding: 10px 15px 10px 15px;

				.more {
					font-size: 12px;
					font-family: Microsoft YaHei;
					font-weight: 400;
					color: #999999;
					line-height: 24px;
					cursor: pointer;
				}
			}

			.service-content {
				padding: 0px 20px;

				.service-item {
					font-size: 12px;
					font-family: Microsoft YaHei;
					font-weight: 400;
					color: #666666;
					line-height: 24px;
					text-align: center;
					margin: 8px 0px;
					cursor: pointer;

					.item-icon {
						width: 60px;
						height: 60px;
						background: #F8F9FA;
						border-radius: 4px;

						img {
							width: 100%;
						}
					}

					.service-txt {
						overflow: hidden;
						height: 24px;
						text-overflow: ellipsis;
						white-space: nowrap;
					}
				}
			}
		}

	}
</style>