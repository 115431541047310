// ====== 方法1：把所有请求的地址封装成一个方法，好处就是有个归总 ======
//引入request.js文件
import request from "./request";

//首页***********************************************************************************


//招聘会列表
export function getJoblists() {
	return request({
		url: `/job_fairs_lists`,
		method: "GET",
		// data 
	});
}
//推荐职位
export function getUserrecommend(data) {
	return request({
		url: `/user_recommend?user_id=${data}`,
		method: "GET",
		// data 
	});
}

//公共服务分类
export function getServicesType() {
	return request({
		url: '/api/getServicesType',
		method: "GET",
	});
}

// 公共服务列表
export function getServices(data) {
	return request({
		url: `/api/getServices?typeId=${data.typeId}&page=${data.page}&pagesize=${data.pagesize}`,
		method: 'GET'
	});
}

// 公共服务详情
export function getServicesDeatil(id) {
	return request({
		url: `/api/getServicesDeatil?id=${id}`,
		method: 'GET'
	});
}

// 有外链的公共服务点击增加阅读量
export function setServicesHits(id) {
	return request({
		url: `/api/setServicesHits?id=${id}`,
		method: 'POST'
	})
}


// 资源下载列表
export function getServicesFiles(data) {
	return request({
		url: `/api/getServicesFiles?page=${data.page}&pagesize=${data.pagesize}`,
		method: 'GET'
	});
}

// 下载资源点击下载增加下载量
export function setServicesFilesOrderNumber(id) {
	return request({
		url: `/api/setServicesFilesOrderNumber?id=${id}`,
		method: 'GET'
	});
}



//职位****************************************************************************
//企业详情
export function getEnterprise(data) {
	return request({
		url: `/enterprise?CompanyId=${data}`,
		method: "GET",
		// data 
	});
}

//职位列表
// export function getPositionlists(data) {
//     return request({
//         url: `/position_lists?page=${data}`,
//         method: "GET", 
//         // data 
//     });
// }
//职位类别
export function getPositionType() {
	return request({
		url: `/getPositionType`,
		method: "GET",
		// data 
	});
}

//街道信息
export function getStreetInformation() {
	return request({
		url: `/api/get_street_information`,
		method: "GET",
		// data 
	});
}

//职位薪资
export function getParams() {
	return request({
		url: `/api/get_params?ParaCode=Salary`,
		method: "GET",
		// data 
	});
}

//学历列表
export function getXueli() {
	return request({
		url: `/api/get_params?ParaCode=Education`,
		method: "GET",
		// data 
	});
}



//职位列表改
export function getPositionlistsgai(data) {
	return request({
		url: `/api/position_lists_web?page=${data.page}${data.name==''||data.name==null?'':'&name='+data.name}${data.position==0||data.position==null?'':'&position='+data.position}${data.Census==0||data.Census==null?'':'&Census='+data.Census}${data.release_date==0||data.release_date==null?'':'&release_date='+data.release_date}${data.Salary==0||data.Salary==null?'':'&Salary='+data.Salary}${data.Education==0||data.Education==null?'':'&Education='+data.Education}&sort=${data.sort}&userid=${data.userid}${data.RecruitmentType?'&RecruitmentType='+data.RecruitmentType:''}`,
		// &Census=${data.Census}&release_date=${data.release_date}&Salary=${data.Salary}&Education=${data.Education}
		method: "GET",
		// data 
	});
}

//灵活用工列表
export function getFlexPositionlists(data) {
	return request({
		url: `/api/flex_position_lists_web?page=${data.page}${data.name==''||data.name==null?'':'&name='+data.name}${data.position==0||data.position==null?'':'&position='+data.position}${data.Census==0||data.Census==null?'':'&Census='+data.Census}${data.release_date==0||data.release_date==null?'':'&release_date='+data.release_date}${data.Salary==0||data.Salary==null?'':'&Salary='+data.Salary}${data.Education==0||data.Education==null?'':'&Education='+data.Education}&sort=${data.sort}&userid=${data.userid}${data.RecruitmentType?'&RecruitmentType='+data.RecruitmentType:''}`,
		// &Census=${data.Census}&release_date=${data.release_date}&Salary=${data.Salary}&Education=${data.Education}
		method: "GET",
		// data 
	});
}

//职位详情
export function getPositiondetails(data) {
	return request({
		url: `/api/position_details_web?EnJobId=${data.id}&userid=${data.userid}`,
		method: "GET",
		// data 
	});
}
//职位详情（企业专用）
export function getenterprisepositiondetails(data) {
	return request({
		url: `/api/enterprise_position_details_web?EnJobId=${data.id}`,
		method: "GET",
		// data 
	});
}
//投递简历
export function getToudiadd(data) {
	return request({
		url: `/api/toudi_add`,
		method: "POST",
		data
	});
}

//收藏职位
export function getAddwishlistjob(data) {
	return request({
		url: `/api/add_wishlist_job`,
		method: "POST",
		data
	});
}

//关注企业
export function getAddwishlist(data) {
	return request({
		url: `/api/add_wishlist`,
		method: "POST",
		data
	});
}

//取消关注
export function getDeletewishlist(data) {
	return request({
		url: `/api/delete_wishlist`,
		method: "POST",
		data
	});
}

//取消收藏
export function getDeletewishlistjob(data) {
	return request({
		url: `/api/delete_wishlist_job`,
		method: "POST",
		data
	});
}


// 企业改

// 企业列表
export function getQiyelist(data) {
	return request({
		url: `/api/enterprise_web?sort=${data.sort}&query=${data.query}&page=${data.page}&userid=${data.userid}&limit=${data.limit}`,
		method: "GET",
		// data 
	});
}

// 企业详情
export function enterpriseDetailWeb(data) {
	return request({
		url: `/api/enterprise_detail_web?CompanyId=${data.id}&userid=${data.userid}`,
		method: "GET",
		// data 
	});
}

// 企业详情之在招岗位
export function enterpriseJobDetailWeb(data) {
	return request({
		url: `/api/enterprise_job_detail_web?CompanyId=${data.id}&page=${data.page}&limit=3&userid=${data.userid}`,
		method: "GET",
		// data 
	});
}

//招聘会*****************************************

// 招聘会列表
export function jobFairsListsWeb(data) {
	return request({
		url: `/api/job_fairs_lists_web?page=${data.page}${data.type==0?'':'&type='+data.type}`,
		method: "GET",
		// data 
	});
}

// 招聘会详情
export function jobFairsDetails(data) {
	return request({
		url: `/api/job_fairs_details_web?RecInfoId=${data}`,
		method: "GET",
		// data 
	});
}

// 招聘会报名企业
export function fairsEnterpriseWeb(data) {
	return request({
		url: `/api/fairs_enterprise_web?RecInfoId=${data.RecInfoId}&page=${data.page}&limit=3&userid=${data.userid}`,
		method: "GET",
		// data 
	});
}

// 招聘会在招岗位
export function fairsJobWeb(data) {
	return request({
		url: `/api/fairs_job_web?RecInfoId=${data.RecInfoId}&page=${data.page}&limit=3&userid=${data.userid}`,
		method: "GET",
		// data 
	});
}

// 企业报名招聘会
export function applyRecruitment(data) {
	return request({
		url: `/api/applyRecruitment`,
		method: "POST",
		data
	})
}

// 获取企业发布岗位列表
export function getCompanyJobList(data) {
	return request({
		url: `/api/getCompanyJobList`,
		method: "POST",
		data
	})
}

// 设置招聘会岗位
export function setCompanyRecruitmentJobs(data) {
	return request({
		url: `/api/setCompanyRecruitmentJobs`,
		method: "POST",
		data
	})
}

//人才****************************************************
// 人才中心列表
export function talentListsWeb(data) {
	return request({
		url: `/api/talent_lists_web?page=${data.page}${data.name==null?'':'&name='+data.name}${data.Education==null?'':'&Education='+data.Education}${data.WorkExperience==null?'':data.WorkExperience=='0'?'&WorkExperience=无':'&WorkExperience='+data.WorkExperience}${data.Sex==null?'':'&Sex='+data.Sex}${data.userid==null?'':'&userid='+data.userid}${data.Census=='0'|data.Census==undefined?'':'&Census='+data.Census}`,
		method: "GET",
		// data 
	});
}

// 人才工作经验列表
export function getGongzuoJinyan() {
	return request({
		url: `/api/get_params?ParaCode=WorkExperience`,
		method: "GET",
		// data 
	});
}

// 人才详情
export function talentDetails(data) {
	return request({
		url: `/api/talent_details_web?jobs_id=${data.id}&userid=${data.userid}`,
		method: "GET",
		// data 
	});
}

// 企业收藏人才
export function addWishlistCompany(data) {
	return request({
		url: `/api/add_wishlist_company`,
		method: "POST",
		data
	})
}

// 企业取消人才收藏
export function deleteWishlistCompany(data) {
	return request({
		url: `/api/delete_wishlist_company`,
		method: "POST",
		data
	})
}

// 企业发送面试邀请
export function interViewAdd(data) {
	return request({
		url: `/api/interViewAdd`,
		method: "POST",
		data
	})
}

//个人中心***********************************************
//用户信息
export function getUseredit(data) {
	return request({
		url: `/api/user_edit_web`,
		method: "POST",
		data
	});
}


// 首页***************************************

export function indexList(data) {
	return request({
		url: `/api/index_list${data==0?'':'?Census='+data}`,
		method: "GET",
		// data 
	});
}

// 首页新闻************************************

export function indexNews() {
	return request({
		url: `/api/index_news`,
		method: "GET",
		// data 
	});
}

// 首页招聘会**********************************

export function indexRecruitment() {
	return request({
		url: `/api/index_recruitment`,
		method: "GET",
		// data 
	});
}

// 首页Banner**********************************

export function indexBanner() {
	return request({
		url: `/api/index_banner`,
		method: "GET",
		// data 
	});
}

// 首页职位类别**********************************

export function indexJobType() {
	return request({
		url: `/api/index_job_type`,
		method: "GET",
		// data 
	});
}

// 首页名企纳才**********************************

export function indexEnterprise(data) {
	return request({
		url: `/api/index_enterprise${data==0?'':'?Census='+data}`,
		method: "GET",
		// data 
	});
}

// 园区职位**********************************

export function indexParkJobs(data) {
	return request({
		url: `/api/index_park_jobs${data==0?'':'?Census='+data}`,
		method: "GET",
		// data 
	});
}


// 登录、注册*********************

// 注册
export function registerWeb(data) {
	return request({
		url: `/api/register_web`,
		method: "POST",
		data
	});
}

// 登录
export function loginWeb(data) {
	return request({
		url: `/api/login_web`,
		method: "POST",
		data
	});
}


// 用户个人信息
export function getUserInfo(data) {
	return request({
		url: `/api/get_user_info`,
		method: "POST",
		data
	});
}

// 刷新简历
export function refresh_resume(data) {
	return request({
		url: `/api/refresh_resume`,
		method: "POST",
		data
	});
}

// 文章资讯

// 列表
export function getArtListWeb(data) {
	return request({
		url: `/api/getArtList_web?TypeId=${data.TypeId}&query=${data.query}&page=${data.page}`,
		method: "GET",
		// data 
	});
}


// 详情
export function getArtInfo(data) {
	return request({
		url: `/api/getArtInfo?Id=${data}`,
		method: "GET",
		// data 
	});
}

// 分类

export function getArtTypeWeb() {
	return request({
		url: `/api/getArtType_web`,
		method: "GET",
		// data 
	});
}

// 政策分类

export function getPolicyMenu(chapter_id) {
	return request({
		url: `/api/SWBigData/policycontent/menu?chapter_id=` + chapter_id,
		method: "GET",
	});
}

// 政策列表

export function getPolicyList(data) {
	return request({
		url: `/api/SWBigData/worksheet/policyList`,
		method: "POST",
		data: {
			key: data.query,
			menu: data.menu,
			page: data.page,
			pagesize: data.pagesize
		}
	});
}

// 政策详情

export function getPolicyDetail(content_id) {
	return request({
		url: `/api/SWBigData/policycontent/detail?content_id=` + content_id,
		method: "GET",
	});
}

// 个人信息***************************

// 完善个人信息
export function userEdit(data) {
	return request({
		url: `/api/user_edit_web`,
		method: "POST",
		data
	});
}

// 工作经历
export function userWorkExperience(data) {
	return request({
		url: `/api/user_work_experience`,
		method: "POST",
		data
	});
}

// 教育经历
export function userEducation(data) {
	return request({
		url: `/api/user_education`,
		method: "POST",
		data
	});
}

// 培训经历
export function userTraining(data) {
	return request({
		url: `/api/user_training`,
		method: "POST",
		data
	});
}

// 项目经历
export function userProject(data) {
	return request({
		url: `/api/user_project`,
		method: "POST",
		data
	});
}

// 所有职位信息
export function userRegister() {
	return request({
		url: `/api/get_position`,
		method: "GET",
		// data
	});
}
// 获取岗位类型
export function getFJobType() {
	return request({
		url: `/api/getFJobType`,
		method: "GET",
		// data
	});
}
// 所有岗位信息
export function getpostion() {
	return request({
		url: `/api/getCensusByPid?pid=19`,
		method: "GET",
		// data
	});
}

//企业信息接口***************************


// 企业信息
export function getCompanyInfo(data) {
	return request({
		url: `/api/getCompanyInfo_web`,
		method: "POST",
		data
	});
}

// 上次登录状态存储
export function changeStatus(data) {
	return request({
		url: `/api/change_status`,
		method: "POST",
		data
	});
}

//修改密码
export function ditPassword(data) {
	return request({
		url: `/api/edit_password`,
		method: "POST",
		data
	});
}

// 个人中心**********************************
//投递记录
export function toudiRecord(data) {
	return request({
		url: `/api/toudi_record`,
		method: "POST",
		data
	});
}

// 改变面试邀请状态
export function interViewUpdate(data) {
	return request({
		url: `/api/interViewUpdate`,
		method: "POST",
		data
	});
}

//获取收藏信息
export function myCollection(data) {
	return request({
		url: `/api/my_collection`,
		method: "POST",
		data
	});
}


// 企业中心*****************************

//获取企业招聘信息
export function enterpriseRecruit(data) {
	return request({
		url: `/api/enterprise_recruit`,
		method: "POST",
		data
	});
}


//获取企业招聘会管理
export function enterpriseRecruitment(data) {
	return request({
		url: `/api/enterprise_recruitment`,
		method: "POST",
		data
	});
}

//获取简历管理
export function enterpriseResume(data) {
	return request({
		url: `/api/enterprise_resume`,
		method: "POST",
		data
	});
}

// 设置岗位状态
export function setJobStatus(data) {
	return request({
		url: `/api/setJobStatus`,
		method: "POST",
		data
	});
}

// 修改发布岗位
export function publishJob(data) {
	return request({
		url: `/api/publishJob`,
		method: "POST",
		data
	});
}

// 企业职位（企业专用）
export function enterprisePositionDetailsWeb(data) {
	return request({
		url: `/api/enterprise_position_details_web?EnJobId=${data}`,
		method: "GET",
		// data
	});
}

// 获取邀请投递
export function toudiList(data) {
	return request({
		url: `/api/toudi_list?UserId=${data.userid}&DataStatus=${data.datastatus}&limit=${data.limit}&page=${data.page}`,
		method: "GET",
		// data
	});
}

// 面试邀请列表
export function interViewlist(data) {
	return request({
		url: `/api/interViewlist`,
		method: "POST",
		data
	});
}

// 企业查看用户简历
export function checkUserInfo(data) {
	return request({
		url: `/api/check_user_info`,
		method: "POST",
		data
	});
}

// 获取街道
export function getTownList(data) {
	return request({
		url: `/api/getTownList?Pid=${data}`,
		method: "GET",
		// data
	});
}

// 获取参数
export function getParam(data) {
	return request({
		url: `/api/get_params?ParaCode=${data}`,
		method: "GET",
		// data 
	});
}


//园区相关
// 获取园区列表
export function parkList() {
	return request({
		url: `/api/park_list`,
		method: "GET",
		// data 
	});
}

// 获取园区详情
export function parkDetail(data) {
	return request({
		url: `/api/park_detail`,
		method: "POST",
		data
	});
}
// 获取园区热招职位
export function parkJobs(data) {
	return request({
		url: `/api/park_jobs`,
		method: "POST",
		data
	});
}
// 获取园区企业
export function parkEnterprise(data) {
	return request({
		url: `/api/park_enterprise`,
		method: "POST",
		data
	});
}


// 专栏管理列表
export function getSpecialListWeb(data) {
	return request({
		url: `/api/getSpecialList_web?TypeId=${data.TypeId}&query=${data.query}&page=${data.page}`,
		method: "GET",
		// data 
	});
}

// 专栏管理详情
export function getSpecialInfoWeb(data) {
	return request({
		url: `/api/getSpecialInfo_web?Id=${data}`,
		method: "GET",
		// data 
	});
}



// 个人用户画像
export function userPortrait(data) {
	return request({
		url: `/api/user_portrait`,
		method: "POST",
		data
	});
}

// 企业用户画像
export function getCompanyPortrait(data) {
	return request({
		url: `/api/getCompanyPortrait`,
		method: "POST",
		data
	});
}


// 修改企业信息
export function companyRegister(data) {
	return request({
		url: `/api/companyRegister`,
		method: "POST",
		data
	});
}

// 修改企业头像
export function companyImgPath(data) {
	return request({
		url: `/api/companyImgPath`,
		method: "POST",
		data
	});
}


// 请求职位类别
export function getParamListZW() {
	return request({
		url: `/api/getParamList?ParaCode=PositionType&ParaType=ZW`,
		method: "get",
		// data 
	});
}

// 灵活用工——发布求职
export function flexJobWanted(data) {
	return request({
		url: `/api/flex_job_wanted`,
		method: "post",
		data
	});
}
//灵活用工——求职列表
export function flexJobWantedList(data) {
	return request({
		url: `/api/flex_job_wanted_list?UserId=${data.UserId}&page=${data.page}`,
		method: "get",
		data
	});
}
//发布灵活用工需求
export function flexJobNeed(data) {
	return request({
		url: `/api/flex_job_need`,
		method: "post",
		data
	});
}
//灵活用工——职位列表
export function flexJobNeedList(data) {
	return request({
		url: `/api/flex_job_need_list?UserId=${data.UserId}&page=${data.page}`,
		method: "get",
		data
	});
}
//灵活用工——个人岗位
export function flexPersonalPosition(data) {
	if (data.UserId) {
		return request({
			url: `/api/flex_personal_position_web?page=${data.page}&UserId=${data.UserId}&name=${data.name}`,
			method: "get",
			data
		});
	} else {
		return request({
			url: `/api/flex_personal_position_web?page=${data.page}&name=${data.name}`,
			method: "get",
			data
		});
	}
}
//灵活用工——求职个人
export function flexPersonalWanted(data) {
	if (data.UserId) {
		return request({
			url: `/api/flex_personal_wanted_web?page=${data.page}&UserId=${data.UserId}&name=${data.name}`,
			method: "get",
			data
		});
	} else {
		return request({
			url: `/api/flex_personal_wanted_web?page=${data.page}&name=${data.name}`,
			method: "get",
			data
		});
	}
}
//灵活用工报名
export function flexJobApply(data) {
	return request({
		url: `/api/flex_job_apply`,
		method: "post",
		data
	});
}
//查看灵活用工我的报名列表
export function myFlexJob(data) {
	return request({
		url: `/api/my_flex_job`,
		method: "post",
		data
	});
}
//查看灵活用工岗位详情
export function flexJobDetail(data) {
	return request({
		url: `/api/flex_job_detail`,
		method: "post",
		data
	});
}
//灵活用工——发送邀请接口
export function flexJobInvite(data) {
	return request({
		url: `/api/flex_job_invite`,
		method: "post",
		data
	});
}
//灵活用工——邀请记录
export function myFlexJobInvite(data) {
	return request({
		url: `/api/my_flex_job_invite`,
		method: "post",
		data
	});
}
//查看我收到的邀请
export function myReceiveInviteJob(data) {
	return request({
		url: `/api/my_receive_invite_job`,
		method: "post",
		data
	});
}
//查看我收到的报名
export function myReceiveApplyJob(data) {
	return request({
		url: `/api/my_receive_apply_job`,
		method: "post",
		data
	});
}

// 获取产业列表
export function getProfessionList(data) {
	return request({
		url: `/api/getProfessionList?Pid=${data.pid}`,
		method: "get"
	});
}

// 获取行业列表
export function getIndustryList(data) {
	return request({
		url: `/api/getIndustryList?Pid=${data.pid}`,
		method: "get"
	});
}


// 获取参数类别
export function getParamList(data) {
	return request({
		url: `/api/getParamList?ParaCode=${data.ParaCode}&ParaType=${data.ParaType}`,
		method: "get",
		// data 
	});
}