import Vue from 'vue'
import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css'
import App from './App.vue'
import router from './router'
import store from './store'
import * as echarts from "echarts"
import {
	formatDate,
	filtersText
} from './utils/common'

Vue.prototype.$echarts = echarts;
Vue.prototype.resetSetItem = function(key, newVal) {
	if (key === 'position') {
		// 创建一个StorageEvent事件
		var newStorageEvent = document.createEvent('StorageEvent');
		const storage = {
			setItem: function(k, val) {
				sessionStorage.setItem(k, val);
				// 初始化创建的事件
				newStorageEvent.initStorageEvent('setItem', false, false, k, null, val, null, null);
				// 派发对象
				window.dispatchEvent(newStorageEvent)
			}
		}
		return storage.setItem(key, newVal);
	} else if (key === 'Census') {
		// 创建一个StorageEvent事件
		var newStorageEvent = document.createEvent('StorageEvent');
		const storage = {
			setItem: function(k, val) {
				sessionStorage.setItem(k, val);
				// 初始化创建的事件
				newStorageEvent.initStorageEvent('setItem', false, false, k, null, val, null, null);
				// 派发对象
				window.dispatchEvent(newStorageEvent)
			}
		}
		return storage.setItem(key, newVal);
	} else if (key === 'release_date') {
		// 创建一个StorageEvent事件
		var newStorageEvent = document.createEvent('StorageEvent');
		const storage = {
			setItem: function(k, val) {
				sessionStorage.setItem(k, val);
				// 初始化创建的事件
				newStorageEvent.initStorageEvent('setItem', false, false, k, null, val, null, null);
				// 派发对象
				window.dispatchEvent(newStorageEvent)
			}
		}
		return storage.setItem(key, newVal);
	} else if (key === 'Salary') {
		// 创建一个StorageEvent事件
		var newStorageEvent = document.createEvent('StorageEvent');
		const storage = {
			setItem: function(k, val) {
				sessionStorage.setItem(k, val);
				// 初始化创建的事件
				newStorageEvent.initStorageEvent('setItem', false, false, k, null, val, null, null);
				// 派发对象
				window.dispatchEvent(newStorageEvent)
			}
		}
		return storage.setItem(key, newVal);
	} else if (key === 'Education') {
		// 创建一个StorageEvent事件
		var newStorageEvent = document.createEvent('StorageEvent');
		const storage = {
			setItem: function(k, val) {
				sessionStorage.setItem(k, val);
				// 初始化创建的事件
				newStorageEvent.initStorageEvent('setItem', false, false, k, null, val, null, null);
				// 派发对象
				window.dispatchEvent(newStorageEvent)
			}
		}
		return storage.setItem(key, newVal);
	} else if (key === 'name') {
		// 创建一个StorageEvent事件
		var newStorageEvent = document.createEvent('StorageEvent');
		const storage = {
			setItem: function(k, val) {
				sessionStorage.setItem(k, val);
				// 初始化创建的事件
				newStorageEvent.initStorageEvent('setItem', false, false, k, null, val, null, null);
				// 派发对象
				window.dispatchEvent(newStorageEvent)
			}
		}
		return storage.setItem(key, newVal);
	} else if (key === 'kkname') {
		// 创建一个StorageEvent事件
		var newStorageEvent = document.createEvent('StorageEvent');
		const storage = {
			setItem: function(k, val) {
				sessionStorage.setItem(k, val);
				// 初始化创建的事件
				newStorageEvent.initStorageEvent('setItem', false, false, k, null, val, null, null);
				// 派发对象
				window.dispatchEvent(newStorageEvent)
			}
		}
		return storage.setItem(key, newVal);
	}else if (key === 'WorkExperience') {
		// 创建一个StorageEvent事件
		var newStorageEvent = document.createEvent('StorageEvent');
		const storage = {
			setItem: function(k, val) {
				sessionStorage.setItem(k, val);
				// 初始化创建的事件
				newStorageEvent.initStorageEvent('setItem', false, false, k, null, val, null, null);
				// 派发对象
				window.dispatchEvent(newStorageEvent)
			}
		}
		return storage.setItem(key, newVal);
	}else if (key === 'shouyetype') {
		// 创建一个StorageEvent事件
		var newStorageEvent = document.createEvent('StorageEvent');
		const storage = {
			setItem: function(k, val) {
				sessionStorage.setItem(k, val);
				// 初始化创建的事件
				newStorageEvent.initStorageEvent('setItem', false, false, k, null, val, null, null);
				// 派发对象
				window.dispatchEvent(newStorageEvent)
			}
		}
		return storage.setItem(key, newVal);
	} else if (key === 'Sex') {
		// 创建一个StorageEvent事件
		var newStorageEvent = document.createEvent('StorageEvent');
		const storage = {
			setItem: function(k, val) {
				sessionStorage.setItem(k, val);
				// 初始化创建的事件
				newStorageEvent.initStorageEvent('setItem', false, false, k, null, val, null, null);
				// 派发对象
				window.dispatchEvent(newStorageEvent)
			}
		}
		return storage.setItem(key, newVal);
	} else {
		// 创建一个StorageEvent事件
		var newStorageEvent = document.createEvent('StorageEvent');
		const storage = {
			setItem: function(k, val) {
				localStorage.setItem(k, val);
				// 初始化创建的事件
				newStorageEvent.initStorageEvent('setItem', false, false, k, null, val, null, null);
				// 派发对象
				window.dispatchEvent(newStorageEvent)
			}
		}
		return storage.setItem(key, newVal);
	}
}

Vue.config.productionTip = false
Vue.use(ElementUI);
Vue.filter('formatDate', formatDate);
Vue.filter('filtersText', filtersText);
Vue.prototype.$bus = new Vue()
new Vue({
	router,
	store,
	render: h => h(App)
}).$mount('#app')
