<template>
	<div class="slider-content">
		<el-carousel height="410px">
			<el-carousel-item v-for="(item,index) in banner" :key="index">
				<img :src="item.img_path" alt="">
			</el-carousel-item>
		</el-carousel>
	</div>

</template>

<script>
// import { getBanner } from "../../http/api";
	export default {
		name: 'Slider',
		props:['banner'],
		data() {
			return {
				// bannerList: [
				// 	{url:require("../../assets/home/banner.png")},
				// 	{url:require("../../assets/home/banner.png")},
				// 	{url:require("../../assets/home/banner.png")},
				// 	{url:require("../../assets/home/banner.png")},
				// 	{url:require("../../assets/home/banner.png")},
				// 	{url:require("../../assets/home/banner.png")},
				// 	{url:require("../../assets/home/banner.png")},
				// 	{url:require("../../assets/home/banner.png")},
				// 	{url:require("../../assets/home/banner.png")},
				// ],

			};
		},
		watch:{
			banner:{
				handler(newV,oldV){
					// console.log(newV,oldV)
				}
			}
		},
		
		created(){
			// this.banner();
		},
		methods:{
			// banner(){
			// 	getBanner().then((res) => {
			// 		console.log(res.data.data);
			// 		for(let i=0;i<res.data.data.length;i++){
			// 		this.bannerList.push({url:'https://api.jywj12333.com'+res.data.data[i].img_path})
			// 		}
			// 		console.log(this.bannerList,999999)
			// 	}).catch((err) => {
			// 		console.log(err);
			// 	});
			// },
		}

	}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
 img{
	 width: 100%;
	 height: 100%;
	 object-fit: fill;
 }
 .el-carousel{
	 :deep .el-carousel__button{
	 	 width: 14px;
	 	 height: 4px;
	 	 background: #000000;
	 }
 }
 
</style>
