<template>
	<div class="footer-content">

		<div class="footer-top">
			<div class="left">
				<div class="list-links">
					<div class="link-title">关于我们</div>
					<li @click="goabout(1)">平台介绍</li>
					<li @click="goabout(2)">联系我们</li>
				</div>
				<div class="list-links">
					<div class="link-title">平台服务</div>
					<li @click="gologing()">注册登录</li>
					<li @click="goqiye()">企业招聘</li>
					<li @click="gozhiwei()">职位搜索</li>
					<li @click="gouser()">简历投放</li>
				</div>
				<div class="list-links">
					<div class="link-title">使用与帮助</div>
					<li @click="goabout(3)">网站声明</li>
					<li @click="goabout(4)">注册协议</li>
				</div>
				<div class="list-links">
					<div class="link-title">联系方式</div>
					<li>电话：028-82728717</li>
					<li>邮箱：211575658@qq.com</li>
					<li style="padding-top: 10px;"><a style="text-decoration: none;color: #ffffff;background: #0a75ab;padding: 5px 50px;" href="javascript:" @click="onjubao">举报电话</a></li>
				</div>
			</div>
			<div class="line"></div>
			<div class="right">
				<div class="title">就业温江，就有<span style="color: #4670FF;">位</span>来</div>
				<div class="icons">
					<div class="img">
						<img src="../assets/gzh.jpg" alt="">
						<div class="img-txt">
							官方公众号
						</div>
					</div>
					<div class="img">
						<img src="../assets/xcx.jpg" alt="">
						<div class="img-txt">
							微信小程序
						</div>
					</div>
				</div>
			</div>
		</div>
		<div class="footer-bottom">
			<div class="content">
				<div class="left">
					<div class="footer-logo">
						<img src="../assets/footer-logo.png" alt="">
					</div>
					<div class="left-txt">
						<li>温江就业局版权所有</li>
						<li>网站由成都思先行科技有限公司提供技术支持</li>
						<li><a href="https://beian.miit.gov.cn/" target="_blank" style="text-decoration: none;color: #999999;">备案号（蜀ICP备15023250号-1）</a></li>
					</div>
				</div>
				<div class="right">
					<img src="../assets/110.png" alt="">
					<img src="../assets/wxb.png" alt="">
				</div>
			</div>

		</div>
		<el-backtop></el-backtop>
	</div>
</template>

<script>
	export default {
		name: 'Footer',
		data() {
			return {

			}
		},
		methods: {
			gologing() {
				this.$router.push('/login')
			},
			goqiye() {
				this.$router.push('/enterprise')
			},
			gozhiwei() {
				this.$router.push('/job')
			},
			gouser() {
				this.$router.push('/talent')
			},
			goabout(id) {
				this.$router.push({
					path: '/about',
					query: {
						id
					}
				})
			},
			onjubao() {
				this.$alert('180 1132 1921', '举报电话', {
					confirmButtonText: '确定',
				});
			}
		}
	}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
	.footer-content {
		background: #FFFFFF;
		height: 400px;
		margin-top: 150px;

		.footer-top {
			width: 1200px;
			height: 280px;
			margin: 0px auto;
			padding: 60px 0px;

			.left {
				display: flex;
				float: left;
				width: 70%;
				margin-right: 80px;

				.list-links {
					width: 25%;
					text-align: left;

					.link-title {
						font-size: 18px;
						font-family: Microsoft YaHei;
						font-weight: 400;
						color: #333333;
						line-height: 24px;
						margin-bottom: 20px;
					}

					li {
						font-size: 14px;
						font-family: Microsoft YaHei;
						font-weight: 400;
						color: #666666;
						margin-bottom: 10px;
						cursor: pointer;
					}
				}
			}

			.line {
				width: 1px;
				height: 160px;
				background: #EEEEEE;
				float: left;
			}

			.right {
				float: right;

				.title {
					margin-bottom: 20px;
					font-size: 18px;
					font-family: PingFang SC;
					font-weight: bold;
					color: #333333;
				}

				.icons {
					display: flex;
					justify-content: space-between;

					.img {
						width: 88px;
						height: 88px;
						margin: 0px 10px;

						.img-txt {
							font-size: 12px;
							font-family: Microsoft YaHei;
							font-weight: 400;
							color: #666666;
						}

						img {
							width: 100%;
						}
					}
				}
			}
		}

		.footer-bottom {
			clear: both;
			border-top: 1px solid #EEEEEE;

			.content {
				height: 100px;
				width: 1200px;
				margin: 0px auto;
				display: flex;
				justify-content: space-between;
				align-items: center;

				.left {
					display: flex;
					align-items: center;

					.left-txt {
						margin-left: 30px;
						font-size: 12px;
						font-family: Microsoft YaHei;
						font-weight: 400;
						color: #999999;
						text-align: left;

						li {
							margin: 2px 0px;
						}
					}
				}

				.right {
					img {
						margin-left: 10px;
					}
				}
			}


		}
	}
</style>