<template>
	<div class="home">
		<div class="top">
			<SearchRegion></SearchRegion>
		</div>
		<div class="top-banner section">
			<div class="left">
				<JobMenu :toplist='toplistdata'></JobMenu>
			</div>
			<div class="right">
				<Slider :banner='bannerdata'></Slider>
			</div>
		</div>
		<div class="fuc-content section">
			<FucList></FucList>
		</div>
		<div class="pub-service section">
			<PublicService></PublicService>
		</div>
		<div class="job-talent section">
			<JobTalent :jobtalentdata='jobtalentlistdata' :rencaidata='rencailistdata'></JobTalent>
		</div>
		<!-- <div class="job-talent section">
			<Yuanqu :job1='job1data' :job2='job2data' :job3='job3data' :job4='job4data'></Yuanqu>
		</div> -->
		<div class="banner-region section">
			<img src="../assets/home/home-banner.png" alt="">
		</div>
		<div class="enterprise section">
			<div class="section-title">名企纳才</div>
			<EnterpriseList :jobdata='joblistdata'></EnterpriseList>
		</div>
		<div class="link section">
			<div class="section-title">友情链接</div>
			<Links></Links>
		</div>
		<Footer></Footer>
		<div class="divmake" v-if="dialogVisible">

		</div>
		<!-- <div class="divdialog" v-if="dialogVisible">
			<div class="divclose" @click="dialogVisible=false"></div>
			<img src="/static/img/dialog.png" style="max-width: 100%;max-height: 600px;cursor: pointer;"
				@click="toArticle()">
		</div> -->

		<el-dialog title=" " :visible.sync="dialogVisible" width="500px" :close-on-click-modal="false"
			:lock-scroll="false" style="background: none;">
			<img src="/static/img/gg.png" style="max-width: 100%;max-height: 600px">
		</el-dialog>
	</div>
</template>

<script>
	import {
		indexList,
		indexBanner,
		indexJobType,
		indexEnterprise,
	} from "../http/api";

	import {
		decryptData
	} from "../utils/common.js";
	// @ is an alias to /src
	import SearchRegion from '@/components/home/SearchRegion.vue'
	import Slider from '@/components/home/Slider.vue'
	import JobMenu from '@/components/home/JobMenu.vue'
	import FucList from '@/components/home/FucList.vue'
	import PublicService from '@/components/home/PublicService.vue'
	import JobTalent from '@/components/home/JobTalent.vue'
	// import Yuanqu from '@/components/home/Yuanqu.vue'
	import EnterpriseList from '@/components/home/EnterpriseList.vue'
	import Links from '@/components/home/Links.vue'
	import Footer from '@/components/footer.vue'
	import env from '../http/env'

	export default {
		name: 'HomeView',
		components: {
			SearchRegion,
			Slider,
			JobMenu,
			FucList,
			PublicService,
			JobTalent,
			EnterpriseList,
			Links,
			Footer,
			// Yuanqu
		},
		data() {
			return {
				toplistdata: '',
				bannerdata: '',
				joblistdata: '',
				jobtalentlistdata: [],
				rencailistdata: '',
				job1data: '',
				job2data: '',
				job3data: '',
				job4data: '',
				dialogVisible: false,
			}
		},
		created() {
			this.resetSetItem("shouyetype", localStorage.getItem('shouyetype'));
			this.getdatelist(sessionStorage.getItem('shouyetype'));
			this.getBanner();
			this.getJobType();
			this.getEnterprise(sessionStorage.getItem('shouyetype'));
		},
		mounted() {

			window.addEventListener('setItem', (e) => {
				this.getdatelist(sessionStorage.getItem('shouyetype'))
			});

			if (this.getDate() != localStorage.getItem('dialogDate')) {
				this.dialogVisible = true;
				localStorage.setItem('dialogDate', this.getDate());
			}
		},
		methods: {
			getDate() {
				const timeOne = new Date()
				const year = timeOne.getFullYear();
				let month = timeOne.getMonth() + 1
				let day = timeOne.getDate()
				month = month < 10 ? '0' + month : month
				day = day < 10 ? '0' + day : day
				return year + '-' + month + '-' + day;
			},
			getBanner() {
				indexBanner().then((res) => {
					this.bannerdata = JSON.parse(decryptData(res.data.data));
					for (let i = 0; i < this.bannerdata.length; i++) {
						this.bannerdata[i].img_path = env.dev.baseUrl + '/upload/' + this.bannerdata[i].img_path;
					}
				})
			},
			getJobType() {
				indexJobType().then((res) => {
					let datas = JSON.parse(decryptData(res.data.data));
					this.toplistdata = datas.splice(5, datas.length - 1);
					this.toplistdata.push({
						Id: 0,
						Name: '全部职位'
					})
				})
			},
			getEnterprise(data) {
				indexEnterprise(data).then((res) => {
					this.joblistdata = JSON.parse(decryptData(res.data.data));
				})
			},
			//获取列表数据
			getdatelist(data) {
				indexList(data).then((res) => {
					let datas = [];
					if (res.data.data) {
						datas = JSON.parse(decryptData(res.data.data));
					}
					for (let i = 0; i < datas.jobs.length; i++) {
						let arrss = datas.jobs[i]
						var c = Date.now();
						var dates = new Date(arrss.EditTime).getTime();
						arrss.zhuangtai = (c - dates) / 24 / 60 / 60 / 60 < 24 ? '最新' : '无'
						this.jobtalentlistdata.push(arrss)
					}
					this.rencailistdata = datas.talent;
				}).catch((err) => {
					console.log(err);
				});
			},
			toArticle() {
				/* this.$router.push({
					path: '/talentxiangqing',
					query: {
						usid: 119282
					}
				}); */
				window.open('https://liuyan.www.gov.cn/2024wwzfgzbgtjy/bmdf.html?q=sc', '_black');
			},
		}
	}
</script>
<style lang="scss" scoped>
	.home {
		width: 100%;
		height: 100%;

		.top {
			background: linear-gradient(to top, #F6FCFF 0%, #CCE7FF 100%);
		}

		.top-banner {
			display: flex;

			.left {
				width: 240px;
				height: 410px;
				background: #5F5F5F;
			}

			.right {
				width: 960px;
				height: 410px;
				// background: #5F5F5F;
				// opacity: 0.3;
			}
		}

		.banner-region {
			height: 120px;
			margin-top: 30px;

			img {
				width: 100%;
				height: 100%;
				object-fit: cover;
			}
		}

		.enterprise,
		.link {
			margin-top: 30px;
		}
	}

	.divmake {
		position: fixed;
		left: 0;
		top: 0;
		width: 100%;
		height: 100%;
		opacity: .5;
		background: #000;
		z-index: 2;
	}

	.divdialog {
		position: fixed;
		top: 30%;
		left: 50%;
		margin-left: -250px;
		z-index: 2;
	}

	.divclose {
		position: absolute;
		right: 16px;
		margin-top: 12px;
		width: 20px;
		height: 20px;
		cursor: pointer;
	}
</style>