import Vue from 'vue'
import VueRouter from 'vue-router'
import HomeView from '../views/HomeView.vue'
const originalPush = VueRouter.prototype.push

VueRouter.prototype.push = function push(location) {

	return originalPush.call(this, location).catch(err => err)

}
Vue.use(VueRouter)

const routes = [{
		path: '/',
		name: 'home',
		component: HomeView
	},
	{
		path: '/job',
		name: 'job',
		// route level code-splitting
		// this generates a separate chunk (job.[hash].js) for this route
		// which is lazy-loaded when the route is visited.
		component: () => import( /* webpackChunkName: "job" */ '../views/JobView.vue')
	},
	{
		path:'/flex-job',
		name:'flex-job',
		component:()=>import('../views/FlexJobView.vue')
	},
	{
		path: '/talent',
		name: 'talent',
		component: () => import('../views/TalentView.vue')
	},
	{
		path: '/enterprise',
		name: 'enterprise',
		component: () => import('../views/EnterpriseView.vue')
	},
	{
		path: '/school',
		name: 'school',
		component: () => import('../views/SchoolView.vue')
	},
	{
		path: '/fair',
		name: 'fair',
		component: () => import('../views/FairView.vue')
	},
	{
		path: '/service',
		name: 'service',
		component: () => import('../views/ServiceView.vue')
	},
	{
		path: '/Jobdetails',
		name: 'Jobdetails',
		component: () => import('../views/JobDetails.vue')
	},
	{
		path: '/recruitDetails',
		name: 'recruitDetails',
		component: () => import('../views/recruitDetails.vue')
	},
	{
		path: '/Talentdetails',
		name: 'Talentdetails',
		component: () => import('../views/TalentDetails.vue')
	},
	{
		path: '/Enterprisedetails',
		name: 'Enterprisedetails',
		component: () => import('../views/EnterpriseDetails.vue')
	},
	{
		path: '/Fairdetails',
		name: 'Fairdetails',
		component: () => import('../views/FairDetails.vue')
	},
	{
		path: '/Zhengqi',
		name: 'zhengqi',
		component: () => import('../views/Zhengqi.vue')
	},
	{
		path: '/Ziyuanmap',
		name: 'ziyuanmap',
		component: () => import('../views/Ziyuanmap.vue')
	},
	{
		path: '/login',
		name: 'login',
		component: () => import('../views/login.vue')
	},
	{
		path: '/ygxq',
		name: 'ygxq',
		component: () => import('../components/people/YgXq.vue'),
	},
	{
		path: '/Mine',
		name: 'mine',
		component: () => import('../views/Mine.vue'),
		children: [{
			path: 'jiben',
			name: 'jiben',
			component: () => import('../components/mine/jiben.vue'),
		},{
			path: 'qyhx',
			name: 'qyhx',
			component: () => import('../components/mine/Qyhx.vue'),
		}, {
			path: 'zhaopin',
			name: 'zhaopin',
			component: () => import('../components/mine/zhaopin.vue'),
		}, {
			path: 'zhaopinhui',
			name: 'zhaopinhui',
			component: () => import('../components/mine/zhaopinguanli.vue'),
		}, {
			path: 'jianli',
			name: 'qjianli',
			component: () => import('../components/mine/jianli.vue'),
		}, {
			path: 'zhanghao',
			name: 'qzhanghao',
			component: () => import('../components/people/Zhanghao.vue'),
		},
		{
			path: 'qiuzhis',
			name: 'qiuzhis',
			component: () => import('../components/people/Qiuzhi.vue'),
		},
		{
			path: 'yonggongs',
			name: 'yonggongs',
			component: () => import('../components/people/Yonggong.vue'),
		},
		{
			path: 'qiuzhixxs',
			name: 'qiuzhixxs',
			component: () => import('../components/people/Qiuzhixx.vue'),
		},
		{
			path: 'yqrxqs',
			name: 'yqrxqs',
			component: () => import('../components/people/YqrXq.vue'),
		},
		{
			path: 'yonggongxxs',
			name: 'yonggongxxs',
			component: () => import('../components/people/Yonggongxx.vue'),
		}]
	},
	{
		path: '/People',
		name: 'people',
		component: () => import('../views/People.vue'),
		children: [{
			path: 'mianshi',
			name: 'mianshi',
			component: () => import('../components/people/Mianshi.vue'),
		}, {
			path: 'toudi',
			name: 'toudi',
			component: () => import('../components/people/Toudu.vue'),
		}, {
			path: 'intoudi',
			name: 'intoudi',
			component: () => import('../components/people/Intoudi.vue'),
		}, {
			path: 'grhx',
			name: 'grhx',
			component: () => import('../components/people/Grhx.vue'),
		}, {
			path: 'jianli',
			name: 'pjianli',
			component: () => import('../components/people/Minejianli.vue'),
		}, {
			path: 'shoucang',
			name: 'shoucang',
			component: () => import('../components/people/Souchang.vue'),
		}, {
			path: 'zhanghao',
			name: 'pzhanghao',
			component: () => import('../components/people/Zhanghao.vue'),
		},{
			path: 'qiuzhi',
			name: 'qiuzhi',
			component: () => import('../components/people/Qiuzhi.vue'),
		},
		{
			path: 'yonggong',
			name: 'yonggong',
			component: () => import('../components/people/Yonggong.vue'),
		},
		{
			path: 'qiuzhixx',
			name: 'qiuzhixx',
			component: () => import('../components/people/Qiuzhixx.vue'),
		},
		{
			path: 'yqrxq',
			name: 'yqrxq',
			component: () => import('../components/people/YqrXq.vue'),
		},
		{
			path: 'yonggongxx',
			name: 'yonggongxx',
			component: () => import('../components/people/Yonggongxx.vue'),
		}]
	},
	{
		path: '/Zhuce',
		name: 'zhuce',
		component: () => import('../views/Zhuce.vue')
	},
	{
		path: '/Jiben',
		name: 'jiben',
		component: () => import('../views/Jiben.vue')
	},
	{
		path: '/services',
		name: 'services',
		component: () => import('../views/Services.vue')
	},
	{
		path: '/servicesdetail',
		name: 'servicesdetail',
		component: () => import('../views/ServicesDetail.vue')
	},
	{
		path: '/servicesfile',
		name: 'servicesfile',
		component: () => import('../views/ServicesFile.vue')
	},
	{
		path: '/TalentXiangqing',
		name: 'talentxiangqing',
		component: () => import('../components/service/TalentXiangqing.vue')
	},
	{
		path: '/TalentXiangqingv',
		name: 'talentxiangqingv',
		component: () => import('../components/service/TalentXiangqingv.vue')
	},
	{
		path: '/Chose',
		name: 'chose',
		component: () => import('../views/Chose.vue')
	},
	{
		path: '/about',
		name: 'about',
		component: () => import('../views/About.vue')
	},
	{
		path: '/Qiyeindex',
		name: 'qiyeindex',
		component: () => import('../views/Qiyeindex.vue')
	},
	{
		path: '/Yuanqu',
		name: 'yuanqu',
		component: () => import('../views/Yuanqu.vue')
	},
	{
		path: '/YuanquDetails',
		name: 'yuanquDetails',
		component: () => import('../views/YuanquDetails.vue')
	},
	{
		path: '/Jobanorther',
		name: 'jobanorther',
		component: () => import('../views/Jobanorther.vue')
	},
	{
		path: '/qitaview',
		name: 'QitaView',
		component: () => import('../views/QitaView.vue')
	},
	{
		path: '/xiangqing',
		name: 'Xiangqing',
		component: () => import('../components/qita/TalentXiangqing.vue')
	},
	
]

const router = new VueRouter({
	//   mode: 'history',
	base: process.env.BASE_URL,
	routes
})

/* 解决vue页面之间跳转，页面不是在顶部的问题,一定要调用 next 方法，否则钩子就不会被销毁
*to 即将进入的路由 from 即将离开的路由 next 放行
*/
router.beforeEach((to, from, next) => {
  document.body.scrollTop = 0; 
  document.documentElement.scrollTop = 0; 
  window.pageYOffset = 0;
  next();
});


export default router
