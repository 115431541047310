<template>
	<div id="app">
		<div class="header">
			<div class="menu">
				<div class="left">

					<div class="zdswicth">
						<i class="iconfont icon-dizhi"></i>{{text}}<span @click="qiehuanbtn()">[切换]</span>
						<div class="zdswicth-box" v-if="quyuflag">
							<el-select v-model="quyu" placeholder="请选择" @change='changes'>
								<el-option v-for="item in quyudata" :key="item.Id" :label="item.Name" :value="item"
									:disabled="item.Id==quyuindex">
								</el-option>
							</el-select>
						</div>
					</div>
					<nav>
						<div class="menu-item">
							<router-link to="/">首页</router-link>
						</div>
						<div class="menu-item">
							<router-link to="/job">职位</router-link>
						</div>
						<div class="menu-item">
							<router-link to="/talent">人才</router-link>
						</div>
						<div class="menu-item">
							<router-link to="/enterprise">企业</router-link>
						</div>
						<div class="menu-item">
							<router-link to="/flex-job">灵活用工</router-link>
						</div>
						<!-- <div class="menu-item">
							<router-link to="/school">校园招聘</router-link>
						</div> -->
						<div class="menu-item">
							<router-link to="/fair">招聘会</router-link>
						</div>
						<div class="menu-item">
							<router-link to="/services">公共服务</router-link>
						</div>
						<div class="menu-item">
							<router-link to="/service">新闻政策</router-link>
						</div>
						<div class="menu-item">
							<router-link to="/qitaview">其他政策</router-link>
						</div>


					</nav>
				</div>
				<div class="right">
					<div class="login" v-show="!islogin">
						<router-link to="/login">登录/注册</router-link>
					</div>
					<div v-show="islogin">
						<el-dropdown @command="handleCommand">
							<el-avatar size="small" :src="circleUrl" style="cursor: pointer;"></el-avatar>
							<el-dropdown-menu slot="dropdown">
								<el-dropdown-item command='people' v-show="login_type==1">个人中心
								</el-dropdown-item>
								<el-dropdown-item command='mine' v-show="login_type==2 & qiyetype==1">企业中心
								</el-dropdown-item>
								<el-dropdown-item command='qzz' v-show="login_type==2">切换求职者</el-dropdown-item>
								<el-dropdown-item command='qy' v-show="login_type==1">切换企业</el-dropdown-item>
								<el-dropdown-item divided command='out'><i class="el-icon-unlock"></i>退出
								</el-dropdown-item>
							</el-dropdown-menu>
						</el-dropdown>
					</div>
					<!-- <div>
						<a href="http://wjrcw.jywj12333.com/" style="text-decoration: none;font-size: 12px;color:#FFF;border: 1px solid #fff;line-height: 27px;height:27px;padding:5px;border-radius: 3px;">旧版入口</a>
					</div> -->
					<!-- <div class="qiehuan">
						<div :class="tabindex==1?'active':'gr'" @click="tab(1)">
							<span>个人</span>
						</div>
						<div :class="tabindex==2?'active':'qy'" @click="tab(2)">
							<span>企业</span>
						</div>
					</div> -->
				</div>
			</div>

		</div>

		<router-view />
	</div>
</template>
<script>
	import {
		changeStatus,
		getTownList
	} from "./http/api";
	import {
		decryptData
	} from "./utils/common.js";
	export default {
		data() {
			return {
				qiyetype: '',
				quyuflag: false,
				quyuindex: 0,
				quyudata: [{
						Id: 0,
						Name: '总站'
					},
					{
						Id: 1,
						Name: '柳城'
					},
					{
						Id: 2,
						Name: '万盛'
					},
				],
				quyu: '',
				text: '总站',
				logindex: true,
				tabindex: 1,
				postdatas: {
					token: '',
					last_login_status: '',
				},
				circleUrl: "https://cube.elemecdn.com/3/7c/3ea6beec64369c2642b92c6726f1epng.png",
				islogin: false,
				login_type: 0,
				grrz: 0,
				qyrz: 0,
			};
		},

		created() {
			if (localStorage.getItem('userid')) {
				this.islogin = true
			} else {
				this.islogin = false
			}
			this.login_type = localStorage.getItem('last_login_status');
			this.grrz = localStorage.getItem('geren');
			this.qyrz = localStorage.getItem('qyrz');
			this.getStreet()
			localStorage.setItem('shouyetype', 0)
			this.resetSetItem("shouyetype", 0);
			this.qiyetype = localStorage.getItem('qiye')

		},
		watch: {
			$route(to, from) {
				if (from.name == 'login') {
					location.reload();
				}
			}
		},
		mounted() {

			window.addEventListener('setItem', (e) => {
				if (localStorage.getItem('userid')) {
					this.islogin = true
				} else {
					this.islogin = false
				}
				this.login_type = localStorage.getItem('last_login_status');
			})
		},
		methods: {
			//获取镇街
			getStreet() {
				getTownList(19).then((res) => {
					this.quyudata = JSON.parse(decryptData(res.data.data));
					this.quyudata.unshift({
						Id: 0,
						Name: '总站'
					})
				})
			},
			changes(e) {
				this.quyuindex = e.Id;
				localStorage.setItem('shouyetype', this.quyuindex)
				this.resetSetItem("shouyetype", this.quyuindex);
				this.text = e.Name
				this.quyuflag = false
			},
			//切换
			qiehuanbtn() {
				this.quyu = ""
				this.quyuflag = !this.quyuflag
				// console.log(this.quyu)
			},
			// 储存上次登录状态
			postdatass(data) {
				changeStatus(data).then((res) => {}).catch((err) => {
					console.log(err);
				});
			},
			handleCommand(command) {
				// this.$message(command);
				if (command == 'people') {
					this.$router.push({
						name: 'pjianli'
					})
				} else if (command == 'mine') {
					this.$router.push({
						name: 'jiben'
					})
				} else if (command == 'qzz') {
					this.postdatas.token = localStorage.getItem('token');
					this.postdatas.last_login_status = 1 * 1
					this.resetSetItem('last_login_status', 1)
					this.postdatass(this.postdatas)
					if (localStorage.getItem('geren') == 1) {
						this.$router.push({
							name: 'pjianli'
						})

					} else {
						this.$router.push('/jiben')
					}

				} else if (command == 'qy') {
					this.postdatas.token = localStorage.getItem('token');
					this.postdatas.last_login_status = 2 * 1
					this.resetSetItem('last_login_status', 2)
					this.postdatass(this.postdatas)
					if (localStorage.getItem('qiye') == 1) {
						this.$router.push({
							name: 'jiben'
						})

					} else {
						this.$router.push('/qiyeindex')
					}
				} else if (command == 'out') {
					localStorage.clear();
					this.$router.push('/')
					location.reload();
				}
			},

			// tab(index) {
			// 	this.tabindex = index;
			// 	if (index == 1) {
			// 		this.postdatas.token = localStorage.getItem('token')
			// 		this.postdatas.last_login_status = 1 * 1
			// 		this.postdatass(this.postdatas)
			// 		if (localStorage.getItem('geren') == 1) {
			// 			this.$router.push('/people')

			// 		} else {
			// 			this.$router.push('/jiben')
			// 		}
			// 	} else {
			// 		this.postdatas.token = localStorage.getItem('token')
			// 		this.postdatas.last_login_status = 2 * 1
			// 		this.postdatass(this.postdatas)
			// 		if (localStorage.getItem('qiye') == 1) {
			// 			this.$router.push('/mine')

			// 		} else {
			// 			this.$router.push('/qiyeindex')
			// 		}
			// 	}
			// }
		}
	}
</script>

<style lang="scss">
	@import url(//at.alicdn.com/t/c/font_3579941_l3w0x27el8.css);

	#app {
		font-family: Avenir, Helvetica, Arial, sans-serif;
		-webkit-font-smoothing: antialiased;
		-moz-osx-font-smoothing: grayscale;
		text-align: center;
		color: #2c3e50;
		background: #F6FCFF;
	}

	* {
		margin: 0;
		padding: 0;
		box-sizing: border-box;
	}

	*::before,
	*::after {
		box-sizing: border-box;
	}

	ul,
	li,
	ol {
		list-style: none;
	}

	.section {
		width: 1200px;
		margin: 0px auto;

		.section-title {
			font-size: 24px;
			font-family: Microsoft YaHei;
			font-weight: 400;
			color: #000000;
			line-height: 24px;
			text-align: left;
		}
	}

	.header {
		background-color: #4670FF;
		height: 48px;

		.menu {
			height: 100%;
			display: flex;
			justify-content: space-between;
			align-items: center;
			width: 1200px;
			margin: 0px auto;
			background-color: #4670FF;

			.left {
				display: flex;

				.zdswicth {
					font-size: 14px;
					font-family: Microsoft YaHei;
					font-weight: 400;
					color: #FFFFFF;
					line-height: 24px;
					margin-right: 80px;
					display: flex;

					i {
						width: 14px;
						height: 16px;
						padding-right: 20px;
					}

					span {
						padding-left: 5px;
						font-size: 12px;
						cursor: pointer;
					}

					.zdswicth-box {
						width: 120px;
						background: #fff;
						position: absolute;
						top: 42px;
						display: flex;
						flex-direction: column;

					}
				}

				nav {
					display: flex;
					align-items: center;

					a {
						color: #fff;
						font-size: 14px;
						font-family: Microsoft YaHei;
						font-weight: 400;
						text-decoration: none;
						margin-right: 2.5rem;
						padding-bottom: 6px;

						&.router-link-exact-active {
							// color: #42b983;
							border-bottom: 4px solid #fff;
						}
					}
				}
			}

			.right {
				width: 200px;
				height: 27px;
				display: flex;
				justify-content: space-around;

				.login {
					width: 81px;
					height: 27px;
					border: 1px solid #F8F9FA;
					border-radius: 4px;

					a {
						font-size: 12px;
						font-family: Microsoft YaHei;
						font-weight: 400;
						color: #FFFFFF;
						line-height: 24px;
						text-decoration: none
					}
				}

				.qiehuan {
					width: 100px;
					height: 27px;
					background: #fff;
					display: flex;
					font-size: 12px;
					color: #fff;
					align-items: center;
					justify-content: center;

					.gr {
						width: 50%;
						height: 100%;
						color: #000;
						line-height: 27px;
					}

					.qy {
						width: 50%;
						height: 100%;
						color: #000;
						line-height: 27px;
					}

					.active {
						width: 50%;
						height: 100%;
						background: #000;
						line-height: 27px;
						color: #fff;
					}
				}
			}

		}

	}
</style>