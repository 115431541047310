<template>
	<div class="enterprise-list">
		<div class="list-item" v-for="(item,index) in jobdata" :key="index" @click="btntiao(item.CompanyId)">
			<div class="enterprise-logo">
				<img :src="logo" alt="">
			</div>
			<div class="enterprise-info">
				<div class="title">{{item.CompanyName}}</div>
				<div class="info">
					{{item.JobNum}}个热招岗位 | {{item.BusinessType}}
				</div>
			</div>
		</div>

	</div>

</template>

<script>
	import {
		enterpriseDetailWeb
	} from "../../http/api";
	export default {
		name: 'EnterpriseList',
		props: ['jobdata'],
		data() {
			return {
				logo: require("../../assets/logo.png"),
				tiaozhuandata: [],
				enterpriseList: [],

			};
		},
		watch: {
			jobdata: {
				handler(newV, oldV) {
					// console.log(newV,oldV)
				}
			}
		},
		methods: {
			btntiao(id) {
				// console.log(id)
				// this.gettiaodata(id);
				this.$router.push({
					path: '/Enterprisedetails',
					query: {
						id
					}
				})
			},
			//请求数据
			gettiaodata(data) {
				// enterpriseDetailWeb(data).then((res) => {
				// // console.log(res,'kekekek');
				// this.tiaozhuandata=res.data.data
				// this.$set(this.tiaozhuandata[0],"qiyeId",data)
				// this.$router.push({ path: '/Enterprisedetails', query: { id:this.tiaozhuandata } })
				// 	// this.enterpriseData=res.data.data
				// }).catch((err) => {
				// 	console.log(err);
				// });
			},
		}

	}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
	.enterprise-list {
		margin-top: 20px;
		display: flex;
		justify-content: space-between;
		flex-wrap: wrap;

		.list-item {
			width: 393px;
			height: 120px;
			background: #FFFFFF;
			display: flex;
			align-items: center;
			padding: 20px;
			margin-bottom: 10px;
			cursor: pointer;

			.enterprise-logo {
				width: 80px;
				height: 80px;
				background: #21542E;

				img {
					width: 100%;
					height: 100%;
					border-radius: 8px;
				}
			}

			.enterprise-info {
				padding-left: 20px;
				text-align: left;
				font-size: 12px;
				font-family: Microsoft YaHei;
				font-weight: 400;
				color: #666666;
				line-height: 24px;
				width: calc(100% - 80px);

				.title {
					font-size: 16px;
					font-family: Microsoft YaHei;
					font-weight: 400;
					color: #333333;
					line-height: 24px;
					margin-bottom: 20px;
				}
			}

		}
	}
</style>
