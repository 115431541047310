<template>
	<div class="zph-content">
		<el-tabs v-model="defaultVaule" @tab-click="handleClick">
			<el-tab-pane v-for="(val,key,i) in zphdata" :key="i" :label="val.StartDate|formatDate" :name="key+''">
				<div class="zph-img" @click="btn(val.RecInfoId)">
					<img :src="imgSrc" alt="">
				</div>
				<div class="zph-title" style="padding: 0 10px;">
					【{{val.StartDate|formatDate}}】{{val.RecruitmentName}}
				</div>
				<div class="zph-info">
					<div class="type">招聘会类型: {{val.RecruitmentType}}招聘会</div>
					<div class="address">招聘会地址: {{val.Address}}</div>
					<!-- <div class="num">剩余展位号: 9</div> -->
					<!-- <div class="zph-bz">备注: {{val.Remark}}</div> -->
					<div class="">参会企业 {{val.CompanyNum}} 家，岗位 {{val.JobNum}} 个 共招 {{val.MemberNum}} 人</div>
					<div class="" style="text-align: right;" v-show="val.Status=='报名中'">
						<el-button type="primary" size="mini" @click="btn(val.RecInfoId)">报名参会</el-button>
					</div>
				</div>


			</el-tab-pane>

			<!-- <el-tab-pane :label="key" :name="key" v-for="(val,key,i) in zphdata" :key="i">
				<div class="news-list">
					<div class="news-item" v-for="(item,index) in val" :key="index">
						<div class="title">{{item.Title}}</div>
						<div class="date">{{item.CreateTime}}</div>
					</div>
				</div>
			</el-tab-pane> -->
		</el-tabs>
	</div>

</template>

<script>
	export default {
		name: 'News',
		props: ['zphdata'],
		data() {
			return {
				defaultVaule: "0",
				imgSrc: 'https://images.common.think1st.cn/jyj/123.png',
			};
		},
		watch: {
			zphdata: {
				handler(newV, oldV) {
					// console.log(newV,898989)
					// let aaa = []
					let aaa = "0";
					
					for (let i in newV) {
						
						if (newV[i].StartDate.substring(0, 10) == this.getNowDate()) {
							aaa = i;
						}
					}
					this.defaultVaule = aaa.toString();
					
				}
			}
		},
		methods: {
			handleClick(tab, event) {
				// console.log(tab, event);
			},
			btn(id) {
				// console.log(id)
				this.$router.push({
					path: '/Fairdetails',
					query: {
						RecInfoId: id
					}
				})
			},
			getNowDate() {
				const timeOne = new Date()
				const year = timeOne.getFullYear()
				let month = timeOne.getMonth() + 1
				let day = timeOne.getDate()
				month = month < 10 ? '0' + month : month
				day = day < 10 ? '0' + day : day
				const NOW_MONTHS_AGO = `${year}-${month}-${day}`
				return NOW_MONTHS_AGO
			}
		}

	}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
	.zph-content {
		width: 293px;
		height: 360px;
		background: #FFFFFF;
		position: relative;
		padding: 0px 15px;

		.zph-img {
			width: 253px;
			height: 130px;
			margin: 0px auto;
			cursor: pointer;

			img {
				width: 100%;
				height: 100%;
				object-fit: cover;
			}
		}

		.zph-title {
			width: 253px;
			// height: 30px;
			height: 48px;
			margin: 0px auto;
			// white-space: nowrap;
			overflow: hidden;
			text-overflow: ellipsis;
			background: rgba(0, 0, 0, 0.5);
			color: #FFFFFF;
			font-size: 12px;
			font-family: Microsoft YaHei;
			font-weight: 400;
			text-align: left;
			padding-left: 10px;
			line-height: 24px;
			cursor: pointer;
		}

		.zph-info {
			margin-top: 20px;
			font-size: 12px;
			font-family: Microsoft YaHei;
			font-weight: 400;
			text-align: left;
			padding-left: 10px;
			line-height: 24px;
			color: #000000;
		}

		:deep .el-tabs__item.is-active {
			color: #000000;
		}

		:deep .el-tabs__item {
			color: #666666;
			padding: 0 10px;
		}

		:deep .el-tabs__item:hover {
			color: #000000;
		}

		:deep .el-tabs__active-bar {
			background-color: #000000;
		}
	}
</style>
